
 <template>
              <v-container>
                <v-card variant="flat">
                    <v-card-title class="themeTitle">Cycle d'accomplissement</v-card-title>
              <v-card-text class="justify-center">
             <div>
              <div   style="    shape-outside: content-box;    shape-margin: 30px;    width: 350px;    float: left;"> <img  style=" position: absolute; top: 50px;text-align: center;left: 10px;height:300px  " src="/cycle-accomplissement.jpg" >    
                 <div >
               
                  <v-row  align="center">
                   
                       <v-col :class="['cycleCell']"  ></v-col>
                       <v-col :class="[ cycleNaissance[4].valeur.length>2?'cycleCellMaitre':'cycleCell','rounded-circle',showCom?'linklookalike':'',cycleNaissance[4].couleur]"  v-on:click="showComModal('4',cycleNaissance[4].valeur)">   
                        <div style=" position: absolute; top: 75px;width: 60px;text-align: center;left: 130px;">{{cycleNaissance[4].valeur}} </div>
                      </v-col>
                       <v-col :class="['cycleCell']"  ></v-col>
                       </v-row>
                       <v-row  align="center">
                        <v-col :class="[ cycleNaissance[1].valeur.length>2?'cycleCellMaitre':'cycleCell','rounded-circle',showCom?'linklookalike':'',cycleNaissance[1].couleur]"   v-on:click="showComModal('1',cycleNaissance[1].valeur)">
                          <div style="  position: absolute; top: 190px; left: 16px;width: 60px;text-align: center;">{{cycleNaissance[1].valeur}} </div>
                  </v-col>
                        <v-col :class="[ cycleNaissance[5].valeur.length>2?'cycleCellMaitre':'cycleCell',showCom?'linklookalike':'',cycleNaissance[5].couleur]"  v-on:click="showComModal('5',cycleNaissance[5].valeur)">   
                           <div style="position: absolute; top: 190px; left: 130px;width: 60px;text-align: center;">{{cycleNaissance[5].valeur}} </div> </v-col>
                        <v-col :class="[ cycleNaissance[3].valeur.length>2?'cycleCellMaitre':'cycleCell',showCom?'linklookalike':'',cycleNaissance[3].couleur]"  v-on:click="showComModal('3',cycleNaissance[3].valeur)"> 
                       
                             <div style="position: absolute; top: 190px; left: 242px;width: 60px;text-align: center;">{{cycleNaissance[3].valeur}} </div>
                       
                    </v-col>
                        </v-row>
                        <v-row  align="center">
                          <v-col :class="['cycleCell']"  ></v-col>
                          <v-col :class="[ cycleNaissance[2].valeur.length>2?'cycleCellMaitre':'cycleCell','rounded-circle',showCom?'linklookalike':'',cycleNaissance[2].couleur]"  v-on:click="showComModal('2',cycleNaissance[2].valeur)">
                             
                              <div style="position: absolute; top: 300px; left: 130px; width: 60px;text-align: center;">{{cycleNaissance[2].valeur}} </div>
                          </v-col>
                          <v-col :class="['cycleCell']"  ></v-col>
                          </v-row>
                       </div>   
                      </div>
                       <p  v-for="item in filterItems" class="content ql-editor pa-1"   v-html="item.content"  :key="item"> </p>
                      </div>
                       </v-card-text>
                </v-card>
                <CommentaireSaisieComponent v-on:updated='this.$emit("commentaireUpdated")' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

              </v-container>
       
 </template>
 // propriete  destinée à recevoir le theme de l'experience

    <script>
      import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
      import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  components: {CommentaireSaisieComponent},
  props: ['cycleNaissance','showCom','etudeId','clientId','commentaires'],
  computed:{
    filterItems() {  return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil):[] },},
  methods: {
    showComModal(itx, value){
      if(this.showCom){
      this.item = itx;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  }
  },
  data() {
    return {
      showCommentaireSaisieComponent: false,
      outil:"Cycle d'accomplissement",
      item:"1",
      crtValue:"1",
      planExpressionItem: ["cérébrale","physique","émotif","Intuitif"      ],
    }
  },
  setup(props) {
    console.log(props.title)
  }
}
    </script>