import { createApp } from 'vue'
import App from './App.vue'
import "vuetify/styles";
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/styles/main.scss'
import router from './router'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App);

const vuetify = createVuetify({


  defaults: {
    VToolbar: {
      VBtn: {   variant:"outlined",
      rounded:"lg" ,
      class:"mx-3"  },
    }
  },
   display: {
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
  VDataTable:{
 dataFooter:{
  itemsPerPageAll:"Tous",
  itemsPerPageText:"lignes par page",
 }},
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
          fa,
          mdi,
        },
      },
  })

app.component('QuillEditor', QuillEditor)
app.use(  vuetify);
app.use(router);

app.mount('#app');
