
      <template>
      <v-sheet class="bg-grey-lighten-2">
        <v-row v-if="!AuthState.isAuthenticated">

          <v-col
            cols="2"
          
          ></v-col>
          <v-col
            cols="8"
      
          >
   
          <v-card class="rounded-xl pa-0 ma-2"   color="#05445E">
    
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
         
            height="50px"
            cover
          >
            <v-card-title  class="text-white text-body-1 "   style="color:black;"
           >Bienvenue</v-card-title>
          </v-img>
          
          <v-card-text style="background-color: white;"> 
        <v-row color="white">
          <v-col color="white"
            cols="4"  class="text-center"> 
            <v-avatar color="white"
            class="ma-3"
            rounded="0"
            size="125"
          >
            <v-img src="l.gif"></v-img>
          </v-avatar>
          <p class="text-center">Le logiciel de Numérologie Créative® 
            répond parfaitement à la méthode   
            créée par Colette Le Floch. 
            Il est accessible en ligne.
            <br/>Laurence Thiévin, référente et formatrice de la Numérologie Créative
      
        </p>
          
          </v-col>
            <v-col cols="8"  color="white" class="text-h5">
              Le logiciel professionnel de la Numérologie Créative® vous offre de nombreuses fonctionnalités :
              <div class="text-h6">
<br/>✓ Gérez vos clients
<br/>✓ Réalisez vos documents : calcul et présentation graphique des thèmes, calendriers, plan de l’année
<br/>✓ Préparez vos séances grâce au dictionnaire
<br/>✓ Editez vos dossiers pdf
</div>
            </v-col>
        </v-row>
        </v-card-text>
              <v-card-actions   style="background-color: white;">
                <v-spacer></v-spacer>   <a href="https://www.numerologie-creative.com/" target="_blank"> <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" > en savoir +</v-btn ></a>
                  <v-spacer></v-spacer>  <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" v-if="!AuthState.isAuthenticated" @click="login">Connexion</v-btn >
                  <v-spacer></v-spacer></v-card-actions>
          </v-card>

          </v-col>
          <v-col
            cols="2"
          
          ></v-col>
       
        </v-row>


        <v-row v-if="AuthState.isAuthenticated">
          <v-col
          class="d-flex d-lg-none  " 
            cols="12"
          
          >
          <v-card class="rounded-lg pa-0 ma-2" width="100%" color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
          
            height="50px"
            cover
          >
            <v-card-title class="text-white text-body-1" style="color:black;"
           >Mes Clients</v-card-title>
          
          </v-img>
            <v-card-text style="background-color: white;">   
              <v-btn  v-on:click="gotoClient" >Acceder à mes clients</v-btn>
           </v-card-text>
          
          </v-card>
          </v-col>

<v-col
  cols="12"
  lg="8"
>

<v-card class="rounded-lg pa-0 ma-2"   color="#05445E">
    
  <v-img
  src="./fond_vague_long.png"
  gradient="to right, #05445EDD, #05445EFF"

  height="50px"
  cover
>
  <v-card-title  class="text-white text-body-1 "   style="color:black;"
 >Bienvenue</v-card-title>
</v-img>

<v-card-text style="background-color: white;"> 
<v-row color="white">
<v-col color="white"
  cols="4"  class="text-center"> 
  <v-avatar color="white"
  class="ma-3"
  rounded="0"
  size="125"
>
  <v-img src="l.gif"></v-img>
</v-avatar>
<p class="text-center"> Bienvenue,
  <br> Avec la Numérologie Créative®, donnez sens à vos évolutions personnelles et professionnelles. Je vous accompagne pour relier vos talents à vos projets.
  <br> Laurence Thiévin</p></v-col>
  <v-col cols="8"  color="white">
    <p class="text-h2">Découvrez la symbolique des nombres pour développer</p>
    <br>
    <p class="text-h2" style="color:#DDC7F6"> vos projets</p>
  </v-col>
</v-row>
</v-card-text>
    <v-card-actions   style="background-color: white;">
      <v-spacer></v-spacer>  <v-btn variant="elevated" rounded="xl" style="background-color:#05445E; color:white" v-if="!AuthState.isAuthenticated" @click="login">Connexion</v-btn >
        <v-spacer></v-spacer></v-card-actions>
</v-card>

  <v-card class="rounded-lg pa-0 ma-2" color="#05445E" v-if="AuthState.isAuthenticated" >

    <v-img
    src="./fond_vague_long.png"
    gradient="to right, #05445EDD, #05445EFF"
 
    height="50px"
    cover
  >
  



  <v-card-title class="text-white text-body-1" style="color:black;">Actualités  <v-btn  style=" float:right;" variant="outlined" @click="saveActualite"> Enregistrer </v-btn></v-card-title>
</v-img>
  <v-card-text style="background-color: white;" >  
    <div style=" min-height:250px">    <QuillEditor  v-if="AuthState.isAuthenticated && !store.user.bloque && store.user.isAuthorized  && store.user.role=='Administrateur'"   theme="snow"  contentType="html"
    :options="editorOption" label="Actualités" v-model:content="actualite"/>
    
    <div v-else id="preview" class="content ql-editor" v-html="actualite"> </div></div>  </v-card-text>
</v-card>



</v-col>

<v-col
class="d-none d-lg-block" 
  cols="12"
  lg="4"
>
<v-card class="rounded-lg pa-0 ma-2"  color="#05445E" v-if="store.authenticated && !store.user.bloque && store.user.isAuthorized" >
  <v-img
  src="./fond_vague_long.png"
  gradient="to right, #05445EDD, #05445EFF"

  height="50px"
  cover
>
  <v-card-title class="text-white text-body-1" style="color:black;"
 >Mes Clients</v-card-title>

</v-img>
  <v-card-text style="background-color: white;">   
    <v-btn  v-on:click="gotoClient" >Acceder à mes clients</v-btn>
 </v-card-text>

</v-card>
</v-col>
</v-row>
<v-dialog v-model="dialogConfirmUpdateAcceuil" max-width="500px">
  <v-card>
    <v-card-title class="text-h5">Message acceuil </v-card-title>
    <v-card-text>
      <p>Le message d'accueil a été enregistré</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogConfirmUpdateAcceuil=false">Valider</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
      </v-sheet>
   


     </template>
     <style>

</style>
<playground-resources lang="json">
  {
    "css": ["https://use.fontawesome.com/releases/v5.1.0/css/all.css"]
  }
</playground-resources>
<script setup>
import { store } from '../store.js'
import messageService from '../services/messageService'
import { useAuth0, AuthState } from "../auth";
import router from "@/router";
import { QuillEditor,Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'


  Quill.register("modules/imageCompressor", imageCompressor);

  const {  login, initAuth } = useAuth0(AuthState);
  initAuth();
</script>
<script>


export default {
mounted() {

  messageService.getAccueil().then((response) => {
    this.actualite = response.data;
  });
},
  components: {
    QuillEditor
  },
  name: 'HomePage',
  methods:{
    saveActualite(){
      messageService.saveAccueil(this.actualite).then(() => {
        this.dialogConfirmUpdateAcceuil =true
      });
    },
    gotoClient(){
            router.push({ name: "clients" });

     },
  },
  data: () => ({
    dialogConfirmUpdateAcceuil: false,
    editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background':[
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"]}],
                          [{ 'align': [] }],
                          ['clean'],
                          ['link', 'image', 'video'],
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
  bienvenue:"un message de bienvenu",
  actualite:"des actualités"
    }),
  props: {
    msg: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
