<template>
<v-container style="height:80dvh; overflow: scroll;   margin: auto;" id="printableZone">

    <v-card v-if="includePageGarde" width="800px" height="1300px" class="border rounded" style="page-break-after: always; margin: auto;">
        <GuardeComponent class="toPrintGuard" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle" :adherent="getUser()"></GuardeComponent>
    </v-card>

    <v-card v-if="includeRecapTheme" width="800px" height="1100px" class="border rounded" style="page-break-after: always;margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint"> 
            <div style="transform: scale(0.80);    top: -0px;    position: absolute;">
                <v-row style="">
                    <v-col cols="6" style="padding-top:0">
                        <ThemePlan :planVie="planVie" showComment="false" v-on:commentaireUpdated='this.refresh()' :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemePlan>

                    </v-col>
                    <v-col cols="6" style="padding-top:0">
                        <ThemeCycle :cycleNaissance="cycleNaissance" v-on:commentaireUpdated='this.refresh()' :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeCycle>
                        <ThemeChampExperience :champExperiences="champExperiences" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeChampExperience>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" style="padding-top:0; padding-left:10px">
                        <ThemeDoubleSpiral style="margin-left:40px" v-on:commentaireUpdated='this.refresh()' :doubleSpiral="doubleSpiral" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeDoubleSpiral>
                    </v-col>
                    <v-col cols="6" style="padding-top:0  ">
                        <ThemeDoubleSpiralDroite style=""  :doubleSpiral="doubleSpiral" :showCom="true" v-on:commentaireUpdated='this.refresh()' :etudeId="etudeId" :clientId="clientId"></ThemeDoubleSpiralDroite>
                    </v-col>

                </v-row>
            </div>
        </div>
        <PiedPageComponent class="toPrintFooter" :user="getUser()"></PiedPageComponent>

    </v-card>
    <v-card v-if="includeCommentaires && includeRecapTheme && (filterCommentaires('Plan de vie').length>0
        ||filterCommentaires('Cycle d\'accomplissement').length>0
        || filterCommentaires('Champ des expériences').length>0
        || filterCommentaires('Spirale d\'involution').length>0
        || filterCommentaires('Spirale d\'evolution').length>0
        )" width="800px" class="border rounded" style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint">
            <p v-for="item in   filterCommentaires('Plan de vie')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
            <p v-for="item in   filterCommentaires('Cycle d\'accomplissement')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
            <p v-for="item in   filterCommentaires('Champ des expériences')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
            <p v-for="item in   filterCommentaires('Spirale d\'involution')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
            <p v-for="item in   filterCommentaires('Spirale d\'evolution')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
        </div>
        <PiedPageComponent :user="   getUser()"></PiedPageComponent>
    </v-card>
    <v-card v-if="includePlanVie" width="800px" height="1100px" class="border rounded" style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemePlan v-on:commentaireUpdated='this.refresh()' class="toPrint " :planVie="planVie" :showCom="true" :solo="true" :etudeId="etudeId" :clientId="clientId" :commentaires="commentaires"></ThemePlan>

        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>

    </v-card>

    <v-card v-if="includeCycleNaissance" width="800px" height="1100px" class="border rounded" style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemeCycle class="toPrint" v-on:commentaireUpdated='this.refresh()' :cycleNaissance="cycleNaissance" :commentaires="commentaires" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeCycle>

        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includeChampExperiences" width="800px" height="1100px" class="border rounded" style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemeChampExperience class="toPrint" :champExperiences="champExperiences" v-on:commentaireUpdated='this.refresh()' :commentaires="commentaires" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeChampExperience>
        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includePlanExpression" width="800px" height="1100px" class="border rounded" style="page-break-after: always;margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemePlanExpression class="toPrint" :planExpression="planExpression" v-on:commentaireUpdated='this.refresh()' :commentaires="commentaires" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemePlanExpression>
        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>
    <v-card v-if="includeFullDoubleSpiral" width="800px"  class="border rounded" style="page-break-after: always;margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint">
            <v-row>
                <v-col cols="6" style="padding-top:0; padding-left:10px">
                    <ThemeDoubleSpiral style="margin-left:40px" v-on:commentaireUpdated='this.refresh()' :doubleSpiral="doubleSpiral" :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeDoubleSpiral>
                </v-col>
                <v-col cols="6" style="padding-top:0; ">
                    <ThemeDoubleSpiralDroite style="" :doubleSpiral="doubleSpiral" v-on:commentaireUpdated='this.refresh()' :showCom="true" :etudeId="etudeId" :clientId="clientId"></ThemeDoubleSpiralDroite>
                </v-col>

            </v-row>
            <span id="commentaire_Full_Spiral"  v-if="includeCommentaires && (filterCommentaires('Spirale d\'involution').length>0 ||filterCommentaires('Spirale d\'évolution').length>0) " >
                <p v-for="item in   filterCommentaires('Spirale d\'involution')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
                <p v-for="item in   filterCommentaires('Spirale d\'évolution')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>
            </span>

        </div>
        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includeDoubleSpiral" width="800px" height="1100px" class="border rounded" style="page-break-after: always;margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemeDoubleSpiral class="toPrint" :doubleSpiral="doubleSpiral" :showComment="includeCommentaires"  :commentaires="commentaires" :showCom="true" :etudeId="etudeId" v-on:commentaireUpdated='this.refresh()' :clientId="clientId"></ThemeDoubleSpiral>
        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includeDoubleSpiralDroite" width="800px" height="1100px" class="border rounded" style="page-break-after: always;margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeader" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <ThemeDoubleSpiralDroite class="toPrint" :doubleSpiral="doubleSpiral" :showComment="includeCommentaires"  :commentaires="commentaires" :showCom="true" :etudeId="etudeId" v-on:commentaireUpdated='this.refresh()' :clientId="clientId"></ThemeDoubleSpiralDroite>
        <PiedPageComponent class="toPrintFooter" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includeCycle" height="900px" width="1300px" class="border rounded landscape-content " style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeaderLandscape" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint" id="cycle9ansprint" style="  position: relative;">
            <CycleComponent :showCom="true" :etudeId="etudeId" v-on:commentaireUpdated='this.refresh()' :commentaires="commentaires" :clientId="clientId" :dateetude="anneeCycle" 
            style="z-index: -100;    position: relative;   left: 80px;top:-80px;
"></CycleComponent>
        </div>
        <PiedPageComponent class="toPrintFooterLandscape" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includeCalendrier" height="900px" width="1300px" class="border rounded landscape-content " style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent class="toPrintHeaderLandscape" :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint" id="calendrierprint" style="margin-top:30px;">
            <CalendrierComponent :showCom="true" :commentaires="commentaires" :etudeId="etudeId" :clientId="clientId" style="padding-bottom: 0px;margin-top:-50px" :dateetude="anneeCalendrier" class="  calendar "></CalendrierComponent>
            <FlatJourEnergieComponent v-on:commentaireUpdated='this.refresh()' :showCom="true" :timeStamp="timeStamp" :etudeId="etudeId" :clientId="clientId" :dateetude="anneetude"> </FlatJourEnergieComponent>
        </div>

        <PiedPageComponent class="toPrintFooterLandscape" :user="   getUser()"></PiedPageComponent>
    </v-card>
    <v-card v-if="includePlan" height="900px" width="1300px" class="border rounded landscape-content " style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent  class="toPrintHeaderLandscape"  :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint" id="planprint" >
            <PlanComponent v-on:commentaireUpdated='this.refresh()' :dateetude="anneePlan" :showComment="includeCommentaires" :showCom="true" :etudeId="etudeId" :clientId="clientId" style="
       transform: scale(0.95);
    margin-top: -50px;
    margin-left: 75px;
 "></PlanComponent>
        </div>
        <PiedPageComponent class="toPrintHeaderLandscape" :user="   getUser()"></PiedPageComponent>
    </v-card>

    <v-card v-if="includePlan && includeCommentaires && filterCommentaires('Plan année').length>0" height="900px" width="1300px" class="border rounded landscape-content " style="page-break-after: always; margin: auto;">
        <v-card-title height="120px">
            <EnteteEtudeComponent :logoPath="logoPath" :dateetude="dateetude" :client="client" :jourPersonnel="jourPersonnel" :moisPersonnel="moisPersonnel" :anneePersonnelle="anneePersonnelle"></EnteteEtudeComponent>
        </v-card-title>
        <div class="toPrint" id="planprintcomm" >
            <p v-for="item in   filterCommentaires('Plan année')" class="content ql-editor pa-1" v-html="item.content" :key="item"></p>

        </div>
        <PiedPageComponent :user="   getUser()"></PiedPageComponent>
    </v-card>
</v-container>
</template>

    
<script setup>

</script><script>
import {
    store
} from '../store.js'
import PiedPageComponent from "../components/PiedPageComponent.vue";
import clientService from "../services/clientService";
import adherentService from '../services/adherentService';
import commentaireService from '../services/commentaireService';
import ThemePlan from "../components/ThemePlan.vue";
import ThemeCycle from "../components/ThemeCycle.vue";
import ThemeChampExperience from "../components/ThemeChampExperience.vue";
import ThemePlanExpression from "../components/ThemePlanExpression.vue";
import EnteteEtudeComponent from "../components/EnteteEtudeComponent.vue";
import ThemeDoubleSpiral from "../components/ThemeDoubleSpiral.vue";
import ThemeDoubleSpiralDroite from "../components/ThemeDoubleSpiralDroite.vue";
import PlanComponent from "../components/PlanComponent.vue";
import CycleComponent from "../components/CycleComponent.vue";
import CalendrierComponent from "../components/CalendrierComponent.vue";
import GuardeComponent from "../components/GuardeComponent.vue";
import FlatJourEnergieComponent from "../components/FlatJourEnergieComponent.vue";


export default {

    props: ['clientId',
        'dateetude',
        'etudeId',
        'client',
        'planVie',
        'cycleNaissance',
        'champExperiences',
        'planExpression',
        'doubleSpiral',
        'includePlanVie',
        'includeCycleNaissance',
        'includeChampExperiences',
        'includePlanExpression',
        'includeDoubleSpiral',
        'includeFullDoubleSpiral',
        'includeDoubleSpiralDroite',
        'includePlan',
        'includeCycle',
        'includeCalendrier',
        'includeRecapTheme',
        'includePageGarde',
        'includeCommentaires',
        "anneeCalendrier",
        "anneePlan",
        "anneeCycle",

    ],
    setup(props) {
        console.log(props.clientId)

    },
    components: {
 
        PiedPageComponent,
        EnteteEtudeComponent,
        ThemeChampExperience,
        ThemePlanExpression,
        ThemeDoubleSpiral,
        ThemeDoubleSpiralDroite,
        ThemeCycle,
        ThemePlan,
        PlanComponent,
        CycleComponent,
        CalendrierComponent,
        GuardeComponent,
        FlatJourEnergieComponent
    },
    watch: {
        clientId(val) {
            if (val)
                this.refresh();
        },
        dateetude(val) {
            if (val)
                this.refresh();
        },
        etudeId(val) {

            if (val)
                this.refresh();
        },
    },
    mounted() {
        this.refresh()

    },
    data() {
        return {
            timeStamp: new Date().getTime(),
            logoPath: "",
            plan9ans: [],
            commentaires: [],
            plansActions: [{}, {}, {}, {}],
            plansCycles: [{
                item3: {}
            }, {
                item3: {}
            }, {
                item3: {}
            }, {
                item3: {}
            }],
            cycle9ans: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
            anneePersonnelle: {
                valeur: ""
            },
            jourPersonnel: {
                valeur: ""
            },
            moisPersonnel: {
                valeur: ""
            },
            agendaMoisJour: [],
            agendaMois: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            moisCycle: [],
            exemple: "exemple",
            search: '',

        }
    },
    computed: {

      
        anneetude() {
            return this.dateetude.substring(0, 4)
        },
    },
    methods: {
     filterCommentaires(outil) {
            return this.commentaires ? this.commentaires.filter(d => d.outil == outil && d.content && d.content.length > 0) : []
        },
        getValeur(aCycle) {
            if (aCycle) return aCycle.valeur
            return ""
        },
        getDetail(aCycle) {
            if (aCycle) return aCycle.detail
            return ""
        },
        getUser() {
            return store.user
        },
        formatedDate(unedate) {
            if (unedate) return (new Date(unedate)).toISOString().substring(0, 10).replaceAll("-", "/")
            else return ""
        },
        refresh() {
            this.commentaires = []
        
                commentaireService.searchAllCom(this.clientId, this.etudeId).then((resutlt) => {
                    this.commentaires = resutlt.data
                });

            this.logoPath = adherentService.getLogoPath(store.user)

            clientService.getCycleNeuf(this.clientId, this.dateetude).then((resutlt) => {
                this.plan9ans = resutlt.data
            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })

            clientService.getCycleNeuf(this.clientId, this.anneetude + "-01-01").then((resutlt) => {
                this.cycle9ans = resutlt.data
                this.timeStamp = new Date().getTime()

            })

            clientService.getCycleAnnee(this.clientId, this.anneetude + "-01-01").then((resutlt) => {
                this.plansCycles = resutlt.data

            })

            clientService.getPlanActionAnnee(this.clientId, this.anneetude + "-01-01").then((resutlt) => {
                this.plansActions = resutlt.data

            })
            clientService.getAgendaMois(this.clientId, this.anneetude + "-01-01").then((resutlt) => {
                this.agendaMois = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })
            clientService.getAgendaMoisJour(this.clientId, this.dateetude).then((resutlt) => {
                this.agendaMoisJour = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })
            clientService.getAnneePersonnelle(this.clientId, this.dateetude).then((resutlt) => {
                this.anneePersonnelle = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })
            clientService.getMoisPersonnel(this.clientId, this.dateetude).then((resutlt) => {
                this.moisPersonnel = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })

            clientService.getJourPersonnel(this.clientId, this.dateetude).then((resutlt) => {
                this.jourPersonnel = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            })
            clientService.getMoisCycle(this.clientId, this.dateetude).then((resutlt) => {
                this.moisCycle = resutlt.data

            }).catch((e) => {
                console.log("serveur KO");
                console.log(e);

            });

            this.timeStamp = new Date().getTime()
        },
    }
}
</script>
