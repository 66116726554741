<template>
    <div>
        <br>
        <br>         <div style="     "> 
            <img   :src="logoPath"   :style="logoStyle()"  alt="logo">    
        </div>  
        <br>
        <br>
        <p class="text-h5 text-center font-weight-bold"  style="color:#05445E">{{adherent.intituleEtude}}</p>
        <br>
        <br>
        <br>
        <br>
        <p class="text-h5 text-center font-weight-bold"  style="color:#05445E">  </p>
        <br><br>
        <br>
        <br>
        <br>
        <br>
        <p class="text-h5 text-center font-weight-bold"  style="color:#05445E">   {{client.nom}} {{client.prenoms[0]}}</p>
        <br>
        <p class="text-h5 text-center font-weight-bold" style="font-style: italic;">   Entretien du  {{formatedDate(dateetude)}}</p>

        <br>
        <div style=" position: absolute;
        bottom: 75px;
        left: 0;
        width:100%">
    
      <span > <p class="text-caption text-center font-weight-bold"  style="color:#05445E">   {{adherent.prenom}}   {{adherent.nom}} </p>
            <p class="text-caption text-center font-weight-bold"  style="color:#05445E">   {{adherent.mail}}</p>
          </span>
    
        </div>
    
       

</div>
</template> 
<script setup>

</script>
    <script>
    import {
    store
} from '../store.js'
    export default {
    props: ['logoPath', 'dateetude', 'client','jourPersonnel','moisPersonnel','anneePersonnelle','adherent' ],
setup(props) {
console.log(props.clientId)

}, 
methods: {
    logoStyle() {
        if(store.user.tailleLogo=="S"){
            return {
                height: '100px',
                display: 'block',
                margin: 'auto'
            }
        }
        if(store.user.tailleLogo=="M"){
            return {
                height: '150px',
                display: 'block',
                margin: 'auto'
            }
        }
        if(store.user.tailleLogo=="L"){

        return {
            height: '300px',
            display: 'block',
            margin: 'auto'
        }
    }
    if(store.user.tailleLogo=="XL"){

return {
    height: '500px',
    display: 'block',
    margin: 'auto'
}
}   return {
                height: '150px',
                display: 'block',
                margin: 'auto'
            }
    },
    formatedDate(date) {
        return new Date(date).toLocaleDateString('fr-FR');
    },
    calculateAge(birthday,dateEtude) { // birthday is a date
    var ageDifMs = (new Date(dateEtude)) - (new Date(birthday).getTime());
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
}
}
</script>