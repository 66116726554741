
      <template>
        <v-container class="text-caption">
        <v-data-table   :items-per-page-options='footerOptions'   :search="search"   :items-per-page="25"  no-data-text="Aucune entrée"   height="75dvh" density="compact"  :items="filterItems"  hide-default-header="true"  :headers="headers">
          <template v-slot:top>
            <v-row>
              <v-col cols="4">
                <v-combobox  label="Outils"  :items="categories"  v-model="outil" v-on:update:model-value="refreshitems"></v-combobox>
               
              </v-col>   
              <v-col cols="4">
                <v-combobox  label="Élément"  :items="elements"  v-model="element"></v-combobox>      </v-col>
              <v-col cols="4">
                <v-text-field v-model="search" label="Rechercher " class="mx-4"></v-text-field>
              </v-col>

            </v-row>
          </template>
          

          <template v-slot:item="{ item }" >
            <tr class="clickable"  @click="editItem(item)"
          
            >
              <td>{{ item.raw.outil }}</td>
              <td>{{ item.raw.itemLabel }}</td>
              <td>{{ item.raw.nombre }}</td>
             
            </tr>
          </template>
        </v-data-table>
        <DictionnairePersonelDetailComponent ref='detailDictionnaire' v-on:updatedDictionnaires="refreshlist"/>
      </v-container>
      </template>
      <script setup>
  import { VDataTable } from 'vuetify/labs/VDataTable'
  import dictionnaireService from '../services/dictionnaireService.js'
</script>
      <script>
        import {
  onMounted,
  ref,
} from 'vue';
        import DictionnairePersonelDetailComponent from '../components/DictionnairePersonelDetailComponent.vue'
    const detailDictionnaire = ref({});
    
        export default {
          setup() {
            
    
            // Before the component is mounted, the value
            // of the ref is `null` which is the default
            // value we've specified above.
            onMounted(() => {
              // Logs: `Headline`
              console.log("adh");
          
            });
        
            return {
              // It is important to return the ref,
              // otherwise it won't work.
              detailDictionnaire,
            };
          },
          mounted() {
            dictionnaireService.getAll().then((resutlt) => {
          this.dictionnaires = resutlt.data.filter(x=>x.outil)
          this.categories = this.dictionnaires.map(x=>x.outil).filter((value, index, self) => self.indexOf(value) === index);
          this.outil = this.categories[0];
          
          this.elements = this.dictionnaires.filter(x=>x.outil ==this.outil).map(x=>x.itemLabel).filter((value, index, self) => self.indexOf(value) === index);
          this.element = this.elements[0];
      }) .catch((e) => {
        console.log("serveur KO");
    console.log(e);
   
});


      },
          data () {
            return {             
              elements:[],
              element:"",
              footerOptions:[
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: 'Tous'}
],
              editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }],
                          [{ 'align': [] }],
                          ['clean'],
                          ['link', 'image', 'video'],
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
        categories: ["Champ d'expérience","Plan d'expression","Spirale d'involution","Cycle d'accomplissement","Plan de vie","Calendrier","Cycle 9 ans","Plan année","Jour énergie"],
              search: '',
              outil: "Champ d'expérience",
              headers: [
                {title: 'Outil', key: 'outil'},
                {title: 'Élément', key: 'itemLabel'},
                {title: 'Nombre', key: 'nombre'},
           
              ],
            dictionnaires: [ 

              ],
            }
          },computed:{
            filterItems() {  return this.dictionnaires.filter(d=> d.outil==this.outil && d.itemLabel == this.element) },},
          methods: {       
            refreshitems() {  this.elements = this.dictionnaires.filter(x=>x.outil ==this.outil).map(x=>x.itemLabel).filter((value, index, self) => self.indexOf(value) === index);
            this.element = this.elements[0];  },
            refreshlist() {   
              dictionnaireService.getAll()
              .then((resutlt) => { this.dictionnaires = resutlt.data
                this.categories = this.dictionnaires.map(x=>x.outil).filter((value, index, self) => self.indexOf(value) === index);
                this.outil = this.categories[0];
               })
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);     
              });},
            
          
            editItem(item) {
         
              this.$refs.detailDictionnaire.open(item.raw);            
            },
          },
        }
      </script>