
 <template>
          <v-container>
            <v-card variant="flat">
                    <v-card-title class="themeTitle">Plan d'expression</v-card-title>
      <v-card-text class="justify-center">   
        <div>
          <div   style="    shape-outside: content-box;    shape-margin: 30px;    width: 350px;    float: left;">    <div
            :class="['planExpression',planExpression[item].couleur,showCom?'linklookalike':'','text-capitalize']" 
            v-on:click="showComModal(item ,planExpression[item].valeur )"
            v-for="item in planExpressionItem"
            :key="item"
         
          >
          {{ adjustLabel(item) }}  {{ planExpression[item].valeur }}
          </div>
        </div>
          <p  v-for="item in filterItems" class="content ql-editor pa-1"   v-html="item.content"  :key="item"> </p>
        </div>
         </v-card-text>
              </v-card>
              <CommentaireSaisieComponent v-on:updated='this.$emit("commentaireUpdated")' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

          </v-container>
       
 </template>
 // propriete  destinée à recevoir le theme de l'experience

    <script>
      import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
      import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  components: {CommentaireSaisieComponent},

  data() {
    return {
      showCommentaireSaisieComponent: false,
      outil:"Plan d'expression",
      item:"1",
      crtValue:"1",
      planExpressionItem: ["cérébrale","physique","émotif","Intuitif"      ],
    }
  },computed:{
    filterItems() {  return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil):[] },},
  methods: {
    adjustLabel(item){
      return item.replace("érébrale", "érébral");
    },
    showComModal(itx, value){
      if(this.showCom){
      this.item = itx;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  },
  },
  props: ['planExpression','showCom','etudeId','clientId','commentaires'],
  setup(props) {
    console.log(props.title)
  }
}
    </script>