
<template>
    <v-container class="pa-0"><p   class="content ql-editor" v-html="commentaire"> </p>
    </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';


  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value','timeStamp'],
        components: {
    
  },
        data () {
          return {
            exemple:"exemple",
            search: '',
            isshow:false,
            commentaire:' ',
            editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }],
                          [{ 'align': [] }],
                          ['clean'],
                          ['link', 'image', 'video'],
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
          }
        },
        methods: {
         
         
        },
        mounted() {
          this.$nextTick(() => {

            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             { 
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });
          });
        },
        watch  : {
          timeStamp(val) {
            console.log("timeStamp",val);
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             {
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });},
          etudeId(val) {
      
            console.log("timeStamp",val);
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             {
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });

    },
   
  },
      }
    </script>