import apiService from './apiService.js'


export default {    
    async getAll() {
        return   apiService.get('Commentaires/');
    },
      
    async get(dicoId) {
        return   apiService.get('Commentaires/'+dicoId);
    },


    async save(clientId, etudeId, outil, item,value, commentaire) {
        var com={Id:"",ClientId:clientId, EtudeId:etudeId, Outil:outil, Item:item, Nombre:value, Content:commentaire};
        return   apiService.post('Commentaires/', com);
    },
    async delete(dicoId) {
        return   apiService.delete('Commentaires/'+dicoId);
    },
    
    async searchAllCom(  clientId, rdvId )
  {
    var request ={ ClientId:clientId,
        RdvId:rdvId,

    };
        return   apiService.post('Commentaires/Search/', request);
    },
    async search(  clientId, rdvId, outil, item,nombre )
  {
    var request ={ ClientId:clientId,
        RdvId:rdvId,
         Outil:outil,
          Item:item,
        Nombre:nombre
    };
        return   apiService.post('Commentaires/Search/', request);
    },

 
    async searchTuple(  clientId, rdvId, outil, tuples )
    {
      var request ={ ClientId:clientId,
          RdvId:rdvId,
           Outil:outil,
           Tuples:tuples,
       
      };
          return   apiService.post('Commentaires/Search/', request);
      },
    async searchWithDefault(  clientId, rdvId, outil, item,nombre,whitderfault )
  {
    var request ={ ClientId:clientId,
        RdvId:rdvId,
         Outil:outil,
          Item:item,
        Nombre:nombre, 
        WithDefault:!!whitderfault
    };
        return   apiService.post('Commentaires/Search/', request);
    },
    

    }