// utils/useAuth0.js
import {createAuth0Client} from '@auth0/auth0-spa-js';
import { reactive } from 'vue';
//import authConfig from '../../auth_config.json'
import apiService from '../services/apiService.js'


export const AuthState = reactive({
    user: null,
    loading: false,
    token:'',
    isAuthenticated: false,
    redirect: window.location.origin,
    auth0: null,
});



export const useAuth0 = (state) => {
    const handleStateChange = async () => {
       
        state.isAuthenticated = !!(await state.auth0.isAuthenticated());
        state.user = await state.auth0.getUser();
       
        state.loading = false;
        if(state.isAuthenticated)
        {

          state.token = await state.auth0.getTokenSilently()
          apiService.defaults.headers.common['Authorization'] = "bearer "+state.token ;

        }
      
    }

    const initAuth = async () => {
        state.loading = true;
        createAuth0Client({
           authorizationParams: {
                audience: 'https://CreanumApi',
                scope: 'openid profile email' 
              }
             ,
            domain: process.env.VUE_APP_AUTH0_DOMAIN,
            clientId: process.env.VUE_APP_AUTH0_CLIENT ,
            audience:'https://CreanumApi',
            cacheLocation: 'localstorage',
            scope: 'openid profile email offline_access' ,
            useRefreshTokens: false,
            redirect_uri: state.redirect
        }).then(async auth => {
            try {
                // If the user is returning to the app after authentication
                
            
                if (
                  window.location.search.includes('code=') &&
                  window.location.search.includes('state=')
                ) {
                  // handle the redirect and retrieve tokens
                  const { appState } = await auth.handleRedirectCallback()
                  console.log(appState)
                  //state = appState
                  state.error = null;
                  
                  // Notify subscribers that the redirect callback has happened, passing the appState
                  // (useful for retrieving any pre-authentication state)
                
                }
              } catch (e) {
                state.error = e
              } finally {
                // Initialize our internal authentication state
                state.isAuthenticated = await auth.isAuthenticated()
                if(state.isAuthenticated)
                {
        
                  state.token = await auth.getTokenSilently()
                  apiService.defaults.headers.common['Authorization'] = "bearer "+state.token ;
        
                }
                state.user = await auth.getUser()
                state.loading = false
              }
            state.auth0 = auth;
            await handleStateChange();
            
        });   

 }

    const login = async () => {
      
       //await state.auth0.loginWithPopup();
       console.log("will try");
        await  state.auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: state.redirect
            }
          });

        await handleStateChange();
    };

    async function handleRedirectCallback() {
        state.loading = true
      
        try {
          await state.auth0.handleRedirectCallback()
          state.user = await state.auth0.getUser()
          state.isAuthenticated = true
        } catch (e) {
          state.error = e
        } finally {
          state.loading = false
        }
      }
      

     

    const logout = async () => {
       
        state.auth0.logout({
            logoutParams: {
              returnTo: state.redirect
            }
          });
    }

    return {
        login,
        logout,
        initAuth,
        handleRedirectCallback
    }

}