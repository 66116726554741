
import {createRouter,createWebHistory } from 'vue-router'
import HomeComponent from '../components/HomeComponent.vue'
import AideComponent from '../components/AideComponent.vue'
import ClientComponent from '../components/ClientComponent.vue'
import ClientDetailComponent from '../components/ClientDetailComponent.vue'
import DictionnaireComponent from '../components/DictionnaireComponent.vue'
import DictionnaireAvanceComponent from '../components/DictionnaireAvanceComponent.vue'
import DictionnairePersonelComponent from '../components/DictionnairePersonelComponent.vue'
import ParametreComponent from '../components/ParametreComponent.vue'
import RepertoireComponent from '../components/RepertoireComponent.vue'
import AdherentComponent from '../components/AdherentComponent.vue'
import ProfilComponent from '../components/ProfilComponent.vue'
import MessageComponent from '../components/MessageComponent.vue'
import DemoComponent from '../components/DemoComponent.vue'
import messageService from '../services/messageService'
import { store } from '../store.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },

  {
    path: '/demo',
    name: 'demo',
    component: DemoComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#home',
    name: 'homepath',
    component: HomeComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },

  {
    path: '/#parametres',
    name: 'parametres',
    component: ParametreComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#clients/:id',
    name: 'client',
    component: ClientDetailComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },  {
    path: '/#clients/',
    name: 'clientCreate',
    component: ClientDetailComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#clients',
    name: 'clients',
    component: ClientComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
   {
    path: '/#dictionnaire',
    name: 'dictionnaire',
    component: DictionnairePersonelComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },


  {
    path: '/#dictionnaireCommun',
    name: 'dictionnaireCommun',
    component: DictionnaireComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#dictionnaireAvance',
    name: 'dictionnaireAvance',
    component: DictionnaireAvanceComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#repertoire',
    name: 'repertoire',
    component: RepertoireComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  },
  {
    path: '/#aide',
    name: 'aide',
    component: AideComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  }
  ,

  {
    path: '/#profile',
    name: 'profile',
    component: ProfilComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return true;
    },
  }
  ,
  {
    path: '/#adherents',
    name: 'adherents',
    component: AdherentComponent,
    beforeEnter: () => {
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      // reject the navigation
      return store.authenticated && !store.user.bloque && store.user.isAuthorized && store.user.role=="Administrateur"
    },
   
  }
  ,
  {
    path: '/#messages',
    name: 'messages',
    component: MessageComponent,
    beforeEnter: () => {
      // reject the navigation
      messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      return store.authenticated && !store.user.bloque && store.user.isAuthorized && store.user.role=="Administrateur"
    },
   
  }
  

  

]

const router = new createRouter({

  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
