
      <template>
      <v-container class="text-caption">

        <v-data-table      :items-per-page-options='footerOptions'   height="75dvh" :search="search"   :items="adherents"  hide-default-header="true"  :headers="headers"
          density="dense" :items-per-page="25"   :sort-by="[{ key: 'nom', order: 'asc' }]"  no-data-text="Aucun Adhérent"     items-per-page-text="Adhérents par page">
          <template v-slot:top>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="search" label="Rechercher " ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-tooltip text="Ajouter un adherent" class="text-caption">
                  <template v-slot:activator="{ props }">   
                    <v-btn @click="addone()"
                    v-bind="props"
                    prepend-icon="mdi:mdi-plus"          
                  >Ajouter</v-btn>
                 
                  </template>
                </v-tooltip>
              </v-col>
              <v-col cols="3">
                <v-tooltip text="Exporter les clients" class="text-caption">
                  <template v-slot:activator="{ props }">   
                    <v-btn @click="exporter()"
                    v-bind="props"
                    prepend-icon="mdi:mdi-download"          
                  >Exporter</v-btn>
                 
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          

          <template v-slot:item="{ item }" >
            <tr class="clickable"  @click="editItem(item)">
              <td class="text-caption">
                <v-tooltip text="Suppression demandée" v-if="item.raw.delAsked">
                  <template v-slot:activator="{ props }">   
                 
                    <v-icon v-bind="props" color="red" >mdi:mdi-account-off </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip text="Bloqué" v-if="item.raw.bloque">
                  <template v-slot:activator="{ props }">   
                 
                    <v-icon v-bind="props" color="red" >mdi:mdi-lock </v-icon>
                  </template>
                </v-tooltip>
              {{ item.raw.nom }} {{ item.raw.prenom }}</td>
              <td class="text-caption ">{{ item.raw.mail }}</td>
              <td class="text-caption">{{ item.raw.role }}</td>
              <td class="text-caption" v-if="item.raw.role != 'Administrateur'">
                <v-tooltip text="Licence échue" v-if="item.raw.hasAlertFinLicence">
                  <template v-slot:activator="{ props }">   
                    <v-icon v-bind="props" color="red" >mdi:mdi-close-circle </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip text="Licence bientôt échue" v-if="item.raw.hasWarningFinLicence && !item.raw.hasAlertFinLicence">
                  <template v-slot:activator="{ props }">   
                    <v-icon v-bind="props" color="orange" >mdi:alert-octagon </v-icon>
                  </template>
                </v-tooltip>
               {{ item.raw.formatedFinLicence }} </td>
               <td class="text-caption" v-else></td>
            
            </tr>
          </template>
        </v-data-table>
        <AdherentDetailComponent ref='detailAdherent' v-on:updatedAdherents="refreshlist"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Etes vous sure de vouloir supprimer cet adhérent ? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteConfirm">Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-container>
      </template>
      <script setup>
  import { VDataTable } from 'vuetify/labs/VDataTable'

</script>
      <script>
     import {
  onMounted,
  ref,
} from 'vue';


    import adherentService from '../services/adherentService'
    import AdherentDetailComponent from '../components/AdherentDetailComponent.vue'
    const detailAdherent = ref({});

        export default {
          setup() {
            
    
    // Before the component is mounted, the value
    // of the ref is `null` which is the default
    // value we've specified above.
    onMounted(() => {
      // Logs: `Headline`
      console.log("adh");
  
    });

    return {
      // It is important to return the ref,
      // otherwise it won't work.
      detailAdherent,
    };
  },
          components: {
             AdherentDetailComponent,
  },
          mounted() {
            adherentService.getAll().then((resutlt) => {
              this.adherents = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    });

   
          },
          data () {
            return {
              footerOptions:[
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: 'Tous'}
],
              search: '',
              toDelete:null,
              dialogDelete:false,
              headers: [
                {title: 'Nom Prénom', key: 'nom'},
                {title: 'Email', key: 'mail'},
                {title: 'Role', key: 'role'},
                {title: 'Fin licence', key: 'formatedFinLicence'},
              ],
            adherents: [],
            }
          },
          methods: {
            closeDelete(){              
              this.dialogDelete = false
              this.toDelete=null
          },
            deleteConfirm(){
              this.dialogDelete = false
              adherentService.delete(this.toDelete.id)
              .then( ()=>  adherentService.getAll()
                          .then((resutlt) => { this.adherents = resutlt.data }))
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);     
              });
            },
            refreshlist() {   
              adherentService.getAll()
              .then((resutlt) => { this.adherents = resutlt.data })
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);     
              });},
              exporter() {
                adherentService.exporter();
            },
              addone() {
              this.$refs.detailAdherent.create();
            },
            deleteItem(item) {
              this.toDelete = item.raw
              this.dialogDelete = true
            
            },
            editItem(item) {
              this.$refs.detailAdherent.open(item.raw.id);            
            },
          },
        }
      </script>