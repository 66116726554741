<template ><v-app class="bg-grey-lighten-2">
  <v-app-bar
  class="d-flex" 
  color="#05445E"
  theme="dark"
  density="compact"
  v-if="AuthState.isAuthenticated"
> <template v-slot:image>
  <v-img
              src="./fond_vague_long.png"
              class="align-end"
      
              gradient="to right, #05445EDD, #05445EFF"
              max-height="50px"
              cover
            ></v-img></template>
  <v-spacer></v-spacer>
  <div v-if="AuthState.isAuthenticated && store.user.role!='Administrateur'" >Bonjour {{ store.user.prenom }} {{ store.user.nom }}.   Votre licence est valide jusqu'au  {{ store.user.formatedFinLicence }}</div>
  <div v-if="AuthState.isAuthenticated && store.user.role=='Administrateur'" >Bonjour {{ store.user.prenom }} {{ store.user.nom }}, vous êtes {{ store.user.role }} . </div>

  <template v-slot:append>

    <v-btn   v-if="AuthState.isAuthenticated" prepend-icon="mdi:mdi-logout"   v-on:click="logout" >Deconnexion</v-btn>
  </template>
</v-app-bar>
  <v-navigation-drawer
  class="d-flex" 
  dark
  width="200px"
  expand-on-hover
    rail
    permanent
    color="#05445E"
    v-if="AuthState.isAuthenticated" 
  >
  <template v-slot:append >
    <div class="text-white ">  
      F{{store.version }}
  B{{backVersion}}</div>
  </template>
  
  <template v-slot:image>
  <v-img
              src="./fond_spirale_long.png"
              class="align-end"
      
              gradient="to bottom, #05445EDD, #05445EFF"
         
              cover
            ></v-img></template>
    <v-list>
    <v-list-item class="text-white "   v-if="AuthState.isAuthenticated" value="detail"  @click="gotoProfil">   
      <template v-slot:prepend>
        <v-icon  @click="gotoProfil" icon="mdi:mdi-account-outline"></v-icon>
        </template>

        <a @click="gotoProfil">Mon Profil</a>  </v-list-item>
</v-list>  

      <v-divider class="mx-3 my-5"></v-divider>

      <v-list density="compact"    v-if="AuthState.isAuthenticated && !store.user.bloque && store.user.isAuthorized" nav>
          <v-list-item class="text-white "    prepend-icon="mdi:mdi-home" title="Accueil" value="home"   v-on:click="gotoHome" ></v-list-item>
             <v-list-item class="text-white "   prepend-icon="mdi:mdi-account-multiple" title="Mes Clients" value="clients"  v-on:click="gotoClient" ></v-list-item>
             <!--<v-list-item prepend-icon="mdi:mdi-wrench" title="Mes Parametres" value="parametres"  v-on:click="gotoParametre" ></v-list-item>-->
             
            <v-list-item class="text-white "   prepend-icon="mdi:mdi-format-list-bulleted" title="Dictionnaire" value="dictionnaire Commun" v-on:click="gotoDictionnaireCommun"></v-list-item>
             <v-list-item  class="text-white "   v-if="store.user.role=='Administrateur'  || store.user.role=='Adherent avance'  " prepend-icon="mdi:mdi-format-list-bulleted" title="Dictionnaire Avancé" value="dictionnaire Avancé" v-on:click="gotoDictionnaireAvance"></v-list-item>
             <v-list-item class="text-white "   prepend-icon="mdi:mdi-format-list-bulleted" title="Dictionnaire Personel" value="dictionnaire" v-on:click="gotoDictionnaire"></v-list-item>
      
             <!--<v-list-item prepend-icon="mdi:mdi-magnify" title="Répértoire" value="repertoire"  v-on:click="gotoRepertoire" ></v-list-item>-->
            <!--<v-list-item prepend-icon="mdi:mdi-help-circle" title="Aide" value="aide"   v-on:click="gotoAide"></v-list-item>-->
        </v-list>
    <v-divider class="mx-3 my-5"></v-divider>
      <v-list density="compact"    v-if="AuthState.isAuthenticated && !store.user.bloque && store.user.isAuthorized  && store.user.role=='Administrateur'"  nav>
          <v-list-item class="text-white "    prepend-icon="mdi:mdi-contacts" title="Adhérents" value="Adhérents"   v-on:click="gotoAdherents"></v-list-item>
        <!--  <v-list-item prepend-icon="mdi:mdi-magnify-plus" title="Demo Taille" value="Demo Taille"   v-on:click="gotoDemo"></v-list-item>-->
          <v-list-item title="Messages" class="text-white "   value="Messages"   v-on:click="gotoMessages">
            <template v-slot:prepend>
        <v-badge location="top"  :content="store.messages.length" color="error" v-if="store.messages.length>0"> <v-icon icon="mdi:mdi-email"></v-icon> </v-badge>
        <v-icon v-else icon="mdi:mdi-email"></v-icon>
        </template>
        </v-list-item>
        </v-list>

        
  </v-navigation-drawer>

  <v-main class="bg-grey-lighten-2 justify-space-between " style="height: 90dvh">
    <v-alert
   v-if="store.authenticated && store.user.hasAlertFinLicence"
    type="error"
    title="Licence éxpirée"
    icon="mdi:mdi-minus-circle"
    border
  >
    Votre licence a expiré ! Pour continuer à utiliser le logiciel, merci de contacter votre administrateur.
    <div class="pa-4 "> <v-btn
    class="text-none"
    color="medium-emphasis"
    min-width="92"
    rounded
    theme="dark"
    variant="outlined"
    @click="askExperie"
  >
    Contacter
  </v-btn>
  &nbsp;
  <v-btn  class="text-none"
  color="medium-emphasis"
 theme="dark"
v-for="stripe in stripesUrl" :key="stripe.id"
  min-width="92"
  rounded
  variant="outlined"><a :href="stripeUrl.PayementLink +'?client_reference_id='+store.user.id" target="_blank">Renouvellez votre abonnement</a></v-btn></div>
  </v-alert>

  <v-alert
  v-if="store.authenticated && store.user.bloque"
  type="error"
  title="Compte bloqué"
  icon="mdi:mdi-minus-circle"
  border
>
  Votre compte a été bloqué par l'administrateur ! Pour continuer à utiliser le logiciel, merci de contacter votre administrateur.
  <div class="pa-4 "> <v-btn
     class="text-none"
     color="medium-emphasis"
     min-width="92"
     rounded
     theme="dark"
     variant="outlined"
     @click="askLocked"
  
   >
     Contacter
   </v-btn> &nbsp;
</div>
</v-alert>
  <v-alert
  v-if="store.authenticated && store.user.hasWarningFinLicence && !store.user.hasAlertFinLicence"
  type="warning"
  title="Licence bientôt éxpirée"
  icon="mdi: mdi-alert-octagon"
  border
>
  Votre licence arrive a expiration le 00/00/000 ! Pour continuer à utiliser le logiciel apres cette date, merci de contacter votre administrateur.
 <div class="pa-4 ">
   <v-btn
     class="text-none"
     color="medium-emphasis"
     min-width="92"
     rounded
     theme="dark"
     variant="outlined"
     @click="askNearExperie"
   >
     Contacter
   </v-btn>
   &nbsp;
   <v-btn  class="text-none"
   color="medium-emphasis"
   v-for="stripe in stripesUrl" :key="stripe.payementLink"
   min-width="92"
   rounded
   theme="dark"
   variant="outlined"><a :href="stripeUrl.payementLink +'?client_reference_id='+store.user.id" target="_blank">stripeUrl.description</a></v-btn>
 </div>
</v-alert>
 
    <router-view   class="ma-2 pa-2" ></router-view>
    <MessageDetailComponent ref='contact'/>
</v-main>
</v-app>
</template>
<script setup>
import "./assets/style.css";
import "./assets/other.css";
import { store } from './store.js'
import { useAuth0, AuthState } from "./auth";
import {  ref,onMounted } from 'vue'
import {  watch } from 'vue'
import apiService from './services/apiService.js'
import adherentService from './services/adherentService'
import messageService from './services/messageService'
// watch works directly on a ref
watch(AuthState, async () => {
 if(AuthState.isAuthenticated && AuthState.token)
 {
  apiService.defaults.headers.common['Authorization'] = "bearer "+AuthState.token ;
  adherentService.me() .then((resutlt) => {

      store.storeUser(resutlt.data)
      store.storeToken(AuthState.token)
      store.storeAuthenticated(true)
    }).catch((e) => {
         
        console.log(e);
       
    });

    messageService.getUnreadMessages() .then((result) => {
      store.storeMessages(result.data)
     }).catch((e) => {
         
         console.log(e);
        
     });
}
})



const {  logout, initAuth } = useAuth0(AuthState);

initAuth();

</script>
<script>
import router from "@/router";
import MessageDetailComponent from './components/MessageDetailComponent.vue'
import membershipOfferService from './services/membershipOfferService'
import infoService from "./services/infoService";
const contact = ref({});
//import adherentService from './services/adherentService'
export default {
  setup() {
    onMounted(() => { 

      infoService.get().then((result) => {
        alert(result.data);
        this.backVersion = result.data;
       }).catch((e) => {
           
           console.log(e);
          
       });
      membershipOfferService.getAll().then((result) => {
      this.stripesUrl = result.data;
     }).catch((e) => {
         
         console.log(e);
        
     });
    });
    return {contact,};
      },
      components: {
             MessageDetailComponent,
  },
  mounted() { 
 
      infoService.get().then((result) => {
      
        this.backVersion = result.data.version;
       }).catch((e) => {
           
           console.log(e);
          
       });
      membershipOfferService.getAll().then((result) => {
      this.stripesUrl = result.data;
     }).catch((e) => {
         
         console.log(e);
        
     });
    },
data() {
    return {
      stripesUrl: [],
      backVersion: "",
    }
  },
  methods: {
    askExperie(){
      this.$refs.contact.create(store.user,"Ma licence a expiré");  

      }, 
      askNearExperie(){
        this.$refs.contact.create(store.user,"Ma licence va expirer");  

      }, 
      
      askLocked(){  
        this.$refs.contact.create(store.user,"Mon compte est bloqué");  
    }, 
  
      gotoRepertoire(){
            router.push({ name: "repertoire" });

      },    gotoAdherents(){
  
            router.push({ name: "adherents" });

     },
     gotoMessages(){
            router.push({ name: "messages" });

     },
     gotoDictionnaire(){
            router.push({ name: "dictionnaire" });

     },
     gotoDictionnaireCommun(){
            router.push({ name: "dictionnaireCommun" });

     },
     gotoDictionnaireAvance(){
            router.push({ name: "dictionnaireAvance" });

     },
     gotoParametre(){
            router.push({ name: "parametres" });

     },
    gotoClient(){
            router.push({ name: "clients" });

     },
     gotoProfil(){
            router.push({ name: "profile" });

     },
     gotoDemo(){
            router.push({ name: "demo" });

     },
      gotoHome(){
            router.push({ name: "home" });

     },
     gotoAide(){
            router.push({ name: "aide" });

     },},
  name: 'App',
  
}
</script>

