
<template>
    <v-container style="padding: 0px;">
        <v-dialog width="800px"  v-model="isshow">
          <v-card class="rounded-xl pa-0 ma-2"   color="#05445E">
    
            <v-img
            src="./fond_vague_long.png"
            gradient="to right, #05445EDD, #05445EFF"
          
            height="50px"
            cover
          >
            <v-card-title  class="text-white text-body-1 "   style="color:black;"
           > {{outil}} - {{itemLabel}} - Nombre {{value}}</v-card-title>
          </v-img>
          
          <v-card-text style="background-color: white;"> 
       <v-row> 
        <v-col cols="4"> <v-btn color="blue darken-1" @click="addGeneral" text >Dictionnaire général</v-btn></v-col>
        <v-col cols="4"> <v-btn color="blue darken-1" @click="addPerso" text >Dictionnaire personel</v-btn></v-col>
        <v-col cols="4"> <v-btn color="blue darken-1" @click="addAvance" text >Dictionnaire avancé</v-btn></v-col>
        
      </v-row>
      <v-row> <v-col cols="12">  <QuillEditor   style="height: 250px"  theme="snow"  contentType="html"
        :options="editorOption" label="Commentaires" v-model:content="commentaire"/> </v-col> </v-row>
          </v-card-text>
              <v-card-actions   style="background-color: white;">
                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="save">Valider</v-btn>
                 </v-card-actions>
          </v-card>
          </v-dialog>
    </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { QuillEditor,Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';
  import dictionnaireService from '../services/dictionnaireService';
  import dictionnaireCommunService from '../services/dictionnaireCommunService';
  import dictionnaireAvanceService from '../services/dictionnaireAvanceService';

  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value'],
        components: {
    QuillEditor
  },
        data () {
          return {
            exemple:"exemple",
            search: '',
            itemLabel:'',
            isshow:false,
            commentaire:'',
            editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background':[
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }],
                          [{ 'align': [] }],
                          ['clean'],
                        
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
          }
        },
        methods: {
          addPerso(){
            dictionnaireService.search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
          addAvance(){dictionnaireAvanceService.search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
          addGeneral(){  
   dictionnaireCommunService .search(this.outil, this.item,this.value ).then((response) =>
             {
              if(response.data && response.data.length>0)
              this.commentaire+=response.data[0].content;})},
    
          close(){
            this.isshow=false
            this.$emit("closed");
          },
          save(){
            this.isshow=false
            commentaireService.save(this.clientId, this.etudeId, this.outil, this.item,this.value, this.commentaire).then((response) => {
              console.log(response.data);
              this.$emit("updated");
            });
           
            this.$emit("closed");
          },
        },
        watch  : {
          show(val) {
            this.commentaire='';
            this.itemLabel=this.item;
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value ).then((response) => {
              if(response.data && response.data.length>0)
              this.commentaire=response.data[0].content;
            else
            this.commentaire='';
            });
            dictionnaireCommunService .search(this.outil, this.item,this.value ).then((response) =>
            {
              this.itemLabel = response.data[0].itemLabel;

            })
            this.isshow=val

    },
   
  },
      }
    </script>