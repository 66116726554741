import { reactive } from 'vue'

export const store = reactive({
    token: '',
    version: '1.0.2',
    user:{},
    messages:[],
    authenticated:false,
    storeToken(aToken) {
        this.token = aToken
      },
      storeUser(aUser) {
        this.user = aUser
      },
      storeMessages(someMessages) {
        this.messages = someMessages
      },
      storeAuthenticated(isAuth) {
        this.authenticated = isAuth
      }
})