<template><v-container>

          <v-card class="rounded-t-xl"   >
            
             <v-toolbar
            
            color="#05445E"
            class="rounded-t-xl"
  
          ><template v-slot:image>
            <v-img
                        src="./fond_vague_long.png"
                        class="align-end"
                
                        gradient="to right, #05445EDD, #05445EFF"
                      
                        cover
                      ></v-img></template>
            <v-btn
              icon
              
              color="white"
              @click="gotoHome"
            >
              <v-icon>mdi:mdi-close</v-icon>
              
               
            </v-btn>
            <v-toolbar-title style="color: white;" color="white">Mon Profil</v-toolbar-title>
            <v-spacer></v-spacer>
          
          

          </v-toolbar>     
            <v-form v-model="valid">
      <v-container>
          <v-row>  <v-col
            cols="12"
            md="4"
            class="pa-1"
          >
            <v-text-field
              v-model="adherent.prenom"
             
           
              label="Prénom"
              required
              hide-details
            ></v-text-field>
          </v-col>
  
          <v-col
            cols="12"
            md="4"
            class="pa-1"
          >
            <v-text-field
              v-model="adherent.nom"
        
              label="Nom"
              hide-details
              required
            ></v-text-field>
          </v-col>      </v-row>
          <v-row>       
         
         </v-row>
         <v-row>   <v-col
            cols="9"
            md="4"
            class="pa-1"
          >
            <v-text-field
              v-model="adherent.mail"
              :rules="emailRules"
              label="E-mail"
              hide-details
              required
              readonly
              disabled 
              prepend-icon="mdi:mdi-email"
            ></v-text-field>
          </v-col>
          <v-col cols="3"  class="pa-1">  <v-checkbox    v-model="adherent.authorizeMail"    hide-details label="Accepte les emails d’information"></v-checkbox> </v-col>
          <v-col
            cols="12"
            md="4"
            class="pa-1"
          >
            <v-text-field
              v-model="adherent.telephone"
              prepend-icon="mdi:mdi-phone"
              label="Téléphone"
              hide-details
              required
            ></v-text-field>
          </v-col>
  
        </v-row>
        <v-row> 
          <v-col cols="12"  class="pa-1">
            <v-textarea  v-model="adherent.adresse"    
            label="Adresse"
             prepend-icon="mdi:mdi-home">
          </v-textarea>
            
          </v-col>
        </v-row>
        <v-row>   
            <v-col cols="8"  class="pa-1">
              <v-row>    
                <v-col cols="8"   class="pa-1">
                  <v-text-field
                    v-model="adherent.societe"
                      prepend-icon="mdi:X"
                    label="Société"
                    hide-details
                  ></v-text-field>
                </v-col>
   
                <v-col cols="4"    class="pa-1" >
                  <v-btn 
                  :loading="isSelecting" 
                  @click="handleFileImport"
              >
                  Choisissez un logo
              </v-btn>
      
              <!-- Create a File Input that will be hidden but triggered with JavaScript -->
              <input 
                  ref="uploader" 
                  class="d-none" 
                  type="file" 
                  @change="onFileChanged"
              />
                </v-col>
              </v-row> 
              <v-row>
                <v-col class="pa-1" cols="8">
                  <v-text-field
                    v-model="adherent.intituleEtude"
                    prepend-icon="mdi:mdi-format-pilcrow"
                    label="Entete Etude"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <v-select
                    v-model="adherent.tailleLogo"
                    :items="tailleLogos"
                    label="Taille Logo"
                    hide-details
                  ></v-select>
                
                </v-col>
              </v-row>
              <v-row> 
  
               <v-col class="pa-1"
            cols="12"
  
          >
            <v-textarea
              v-model="adherent.pied1"
              rows="2"
              max-rows="2"
              prepend-icon="mdi:mdi-format-pilcrow"
              label="Pied Gauche"
              hide-details
              
            ></v-textarea>
          </v-col>
         
            <v-col class="pa-1"
            cols="12"
  
          >
            <v-textarea
              v-model="adherent.pied2"
              prepend-icon="mdi:mdi-format-pilcrow"
              rows="2"
              max-rows="2"
              label="Pied centre"
              hide-details
              
            ></v-textarea>
          </v-col>
          <v-col class="pa-1"
            cols="12"
  
          >
            <v-textarea
              v-model="adherent.pied3"
              prepend-icon="mdi:mdi-format-pilcrow"
              rows="2"
              max-rows="2"
              label="Pied droit"
              hide-details
              
            ></v-textarea>
          </v-col>
         
        </v-row>
        </v-col>
        <v-col class="pa-1"  cols="4"> 


           <v-img
           v-if="logoPath"
          :width="200"
          cover
          :src="logoPath"
        ></v-img>          <v-img
        v-else
        :width="200"
        cover
        src="nologo.png"
      ></v-img></v-col>   </v-row>
<br/>
<br/>
<v-row>
  <v-col  cols="4"> </v-col> 
  <v-col  cols="4"><v-btn    v-for="stripe in stripesUrl" :key="stripe.payementLink" color="#05445E"  class="text-white " size="x-large" block><a :href="stripe.payementLink +'?client_reference_id='+this.adherent.id" target="_blank" class="text-white ">{{stripe.description}}</a></v-btn> </v-col> 
  <v-col  cols="4"> </v-col> 
</v-row>
<br/>
  <hr/>
<v-alert   type="error"
title="Zone à risque"
icon="mdi: mdi-alert-octagon" > Les actions suivantes presentent un risque et ne doivent pas etre faite sans consideration </v-alert>
<v-row>
  <v-col  cols="1"> </v-col> 
 <v-col  cols="4">  <v-btn  
  @click="askChange"
>
  Changer le mot de passe.
</v-btn></v-col> 
<v-col  cols="2"> </v-col> 
 <v-col  cols="4">    <v-btn  
  prepend-icon="mdi:mdi-delete-forever" 
  @click="askDelete"
>
  Demander la suppression de mon compte
</v-btn></v-col>
<v-col  cols="1"> </v-col> 
</v-row>

<v-card-actions>
 <v-btn   color="#05445E"  variant="flat"  class="text-white " 
@click="save"
>
Enregistrer
</v-btn>
</v-card-actions>
      </v-container>
    
    </v-form>

          </v-card>


    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Etes vous sure ? </v-card-title>
      <v-card-text>
         Est vous sure de vouloir demander la suppression de votre compte ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChange" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Changement mot de passe</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newPassword"
            :rules="mdpRules"
            :append-icon="show1 ? 'mdi:mdi-eye' : 'mdi:mdi-eye-off'"
            @click:append="show1 = !show1"
            :type="show1 ? 'text' : 'password'"
            clearable
            label="Nouveau mot de passe"
            required
          ></v-text-field>
          <v-text-field
          clearable
          :append-icon="show2 ? 'mdi:mdi-eye' : 'mdi:mdi-eye-off'"
            @click:append="show2 = !show2"
            :type="show2 ? 'text' : 'password'"
            v-model="newPasswordConfirm"
            :rules="confirmRules"
            label="Confirmation"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeChange">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="confirmChange">Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="messageChange" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Changement mot de passe</v-card-title>
        <v-card-text>
          <v-alert    type="error"
    title="Mot de passe invalide"
    icon="mdi:mdi-minus-circle"
    border>{{ messageErreur }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="confirmMessage">Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container></template>

  
<script>
import adherentService from '../services/adherentService'
import router from "@/router";
import { store } from '../store.js'
import membershipOfferService from '../services/membershipOfferService'
export default {
    mounted() {
              this.adherent = store.user
              this.isSocialLogin= this.adherent && this.adherent.idexterne&& !this.adherent.idexterne.startsWith('auth');
              this. logoPath= adherentService.getLogoPath(this.adherent) 
              membershipOfferService.getAll().then((offers)=>{this.stripesUrl=offers.data})  
          },
data () {
  return {
    stripeUrl: process.env.VUE_APP_ABO_URL,
    logo:null,
    tailleLogos:["S","M","L","XL"],
    isSelecting: false,
    selectedFile: null,
    stripesUrl:[],
    logoPath:"",
    newPassword:"",
    newPasswordConfirm:"",
    dialog: false,
    messageErreur:"",
    messageChange:false,
    hasError:false,
    dialogChange:false,
    dialogDelete:false,
    show1: false,
    show2: false,
    isSocialLogin:true,
    renouv:false,
    menu2:false,
    renouvellement:"",
    valid: false,
    adherent:{ prenom: '',id:'',role:'',
nom: '',  mail: ''},
nameRules: [
  value => {
    if (value) return true

    return 'Name is required.'
  },
  value => {
    if (value?.length <= 10) return true

    return 'Name must be less than 10 characters.'
  },
],

mdpRules: [
  value => {
    if (value) return true

    return 'Le mot de passe est obligatoire.'
  },
  value => {
    if (value?.length > 7) return true

    return 'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial'
  },
],
confirmRules: [
  value => {
    if (value) return true
    return 'La confirmation est obligatoire.'
  },
  value => {
    if (value== this.newPassword) return true
    return 'Le mot de passe et la confirmation doivent être identiques.'
  },
],
emailRules: [
  value => {
    if (value) return true

    return 'E-mail is requred.'
  },
  value => {
    if (/.+@.+\..+/.test(value)) return true

    return 'E-mail must be valid.'
  },
  
],
  }
},
emits: ['updatedAdherents'],
methods: {
  handleFileImport() {
                this.isSelecting = true;

                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });
                
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                 this.logo = e.target.files;
                if(this.logo && this.logo.length>0  && this.logo[0])     this. logoPath=   URL.createObjectURL(this.logo[0])

                // Do whatever you need with the file, liek reading it with FileReader
            },
  gotoHome(){
            router.push({ name: "home" });

     },
  changeLogo() {  if(this.logo && this.logo.length>0  && this.logo[0])     this. logoPath=    URL.createObjectURL(this.logo[0])
  },
  confirmMessage(){
    this.messageChange=false
  },
  askChange(){
    this.dialogChange=true
    this.newPassword=""
    this.newPasswordConfirm=""
  },
  closeChange(){
    this.dialogChange=false
  },
  confirmChange(){
    adherentService.askChangePassword(this.adherent,this.newPassword)
    .then(()=>{ this.hasError=false  })
    .catch((err)=>{
      this.hasError=true
      this.messageErreur=err.response.data
      if(err.response.data.message.includes("PasswordStrengthError"))
      {
        this.messageErreur="Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial"
      }
    })
    .finally(()=>{  
      this.messageChange=true;
      this.dialogChange=false
    })  
 
  },
  closeDelete(){
    this.dialogDelete=false
  },
  
  askDelete() {
    this.dialogDelete=true
  },
  confirmDelete(){
      this.dialogDelete=false
  if(this.valid)
  {
  
    adherentService.askDelete( this.adherent)
    .then(() => {
      this.dialog=false;
      this.$emit('updatedAdherents');
     })
    .catch((e) => {
      console.log("serveur KO");
      console.log(e);
    });
  }
},
      save() {
  
        if(this.valid)
        {
          if(this.logo && this.logo.length>0  && this.logo[0]) adherentService.saveLogo( this.adherent,this.logo[0])
          adherentService.saveMe( this.adherent)
          .then(() => {
            this.dialog=false;
            this.$emit('updatedAdherents');
           })
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);
          });
        }
      },
}
}
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
transition: transform .2s ease-in-out;
}
</style>