
<template>
    <v-container class="pa-0" v-if="commentaire">
       <div v-if="!!!withoutHeader" :class=" getCouleur(value)"> Filtre {{item}} - {{value}}</div>
       <div  id="preview" class="content ql-editor pa-1" v-html="commentaire" > </div>
      </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';


  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value','timeStamp', 'withoutHeader', 'withDefault'],
        components: {
    
  },
        data () {
          return {
            Couleurs:[ "noire","rouge","bleu","jaune","marron","vert_clair","rose","violet","vert_fonce","safran"],
            exemple:"exemple",
            search: '',
            isshow:false,
            commentaire:'',
            itemLabel:'',
            editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"]}],
                          [{ 'align': [] }],
                          ['clean'],
                          ['link', 'image', 'video'],
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
          }
        },
        methods: {
          getCouleur(chiffre)
          {
           
    if(chiffre=="11/2") return this.Couleurs[2]; 
    if(chiffre=="22/4") return this.Couleurs[4]; 
    if(chiffre=="33/6")  return this.Couleurs[6]; 
    if( chiffre=="44/8") return this.Couleurs[8];
    if(chiffre=="55/1") return this.Couleurs[1];
    if(chiffre=="66/3") return this.Couleurs[3];
    if( chiffre=="77/5") return this.Couleurs[5];
    if(chiffre=="88/7") return this.Couleurs[7];
    if(chiffre=="99/9") return this.Couleurs[9];
    if(chiffre=="10") return this.Couleurs[1]; 
    if(chiffre=="11") return this.Couleurs[2]; 
    if(chiffre=="22") return this.Couleurs[4]; 
    if(chiffre=="33")  return this.Couleurs[6]; 
    if(chiffre=="44") return this.Couleurs[8];
    if(chiffre=="55") return this.Couleurs[1];
    if(chiffre=="66") return this.Couleurs[3];
    if( chiffre=="77") return this.Couleurs[5];
    if(chiffre=="88") return this.Couleurs[7];
    if(chiffre=="99") return this.Couleurs[9];
    
    var val = parseInt(chiffre);
    return this.Couleurs[val];

          }
         
        },
        mounted() {   
          this.$nextTick(() => {
       
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault ).then((response) => {
              if(response.data && response.data.length>0)
             { 
       
              this.commentaire=response.data[0].content;
              this.  itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });
          });
        },
        watch  : {
          timeStamp() {
           
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault).then((response) => {
              if(response.data && response.data.length>0)
             {
          
              this.commentaire=response.data[0].content;
              this.  itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });},
          etudeId(val) {
            var tartouile= val
            if(tartouile!= val){
            this.commentaire="";
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault ).then((response) => {
              if(response.data && response.data.length>0)
             {
             
              this.commentaire=response.data[0].content;
              this.itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });
          }

    },
   
  },
      }
    </script>