
<template>
  <v-container class="text-caption"   v-resize="onResize" >

    <v-data-table   :search="search"   :height="computedHeight"
    :custom-filter="customsearch"
     :items="clients"  
      fixed-header
      :items-per-page-options='footerOptions'  
      :headers="headers" density="compact" 
      :items-per-page="25" 
        :sort-by="[{ key: 'nom', order: 'asc' }]" 
          no-data-text="Aucun client" 
           items-per-page-all="Tous"  
            items-per-page-text="Clients par page">
      <template v-slot:top>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="search" label="Rechercher " ></v-text-field>
          </v-col>
      
          <v-col cols="3">
            <v-tooltip text="Ajouter un client" class="text-caption">
              <template v-slot:activator="{ props }">   
                <v-btn @click="addone()"
                v-bind="props"
                prepend-icon="mdi:mdi-plus"    
                color="#05445E"  variant="flat"  class="text-white "       
              >Ajouter un client</v-btn>
             
              </template>
            </v-tooltip>
          </v-col>
              <v-col cols="3">
            <v-tooltip text="Exporter les clients" class="text-caption">
              <template v-slot:activator="{ props }">   
                <v-btn @click="exporter()"
                v-bind="props"
                prepend-icon="mdi:mdi-download"          
              >Exporter</v-btn>
             
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      

      <template v-slot:item="{ item }" >
        <tr class="clickable"  @click="editItem(item)">
          <td class="text-caption">      {{ item.raw.nom }} {{ item.raw.prenoms[0] }}</td>
          <td class="text-caption">{{ item.raw.mail }}</td>
          <td class="text-caption">{{ convertDate(item.raw.naissance) }}</td>
         
          
        </tr>
      </template>
    </v-data-table>
  
    <v-dialog v-model="dialogDelete" max-width="500px">
  <v-card>
    <v-card-title class="text-h5">Etes vous sure de vouloir supprimer ce client ? </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
      <v-btn color="blue darken-1" text @click="deleteConfirm">Valider</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
  </v-container>
  </template>
  <script setup>
import { VDataTable } from 'vuetify/labs/VDataTable'

</script>
  <script>
 import {
onMounted,
ref,
} from 'vue';
 


import clientService from '../services/clientService'
import router from "@/router";

const detailClient = ref({});

    export default {
      setup() {
        

// Before the component is mounted, the value
// of the ref is `null` which is the default
// value we've specified above.
onMounted(() => {
  // Logs: `Headline`
  console.log("clt");


});

return {
  // It is important to return the ref,
  // otherwise it won't work.     prenom: "", id: "",  nom: "",nom_naissance: "", mail: "", Prenoms:[], telephone:"" },
  detailClient,
};
},
      components: {
  
},
      mounted() {
        this.onResize();
        clientService.getAll().then((resutlt) => {
          this.clients = resutlt.data.filter(x=>x.prenoms)
      }) .catch((e) => {
        console.log("serveur KO");
    console.log(e);
   
});


      },
      data () {
        return {
          computedHeight:'100px',
          search: '',
          footerOptions:[
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: 'Tous'}
],
          toDelete:null,
          dialogDelete:false,
          headers: [
            {title: 'Nom', key: 'nom'},
            {title: 'Email', key: 'mail'},
            {title: 'Date de naissance', key: 'naissance'},
          ],
        clients: [],
        }
      },
      computed: {
      
      },
      methods: {

        onResize() {
          this.computedHeight= (window.innerHeight - 220)+'px';
    },
        customsearch (value, query, item) {
          console.log(item);
          //ajouter split sur espace
        return value != null &&
          query != null &&
          typeof value === 'string' &&
         ( value.toString().toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) !== -1 )
      },
        convertDate(adate){
          if(adate)
            return (new Date(adate)).toLocaleDateString('fr-FR') 
          return "";
        },
        closeDelete(){              
          this.dialogDelete = false
          this.toDelete=null
      },
        deleteConfirm(){
          this.dialogDelete = false
          clientService.delete(this.toDelete.id)
          .then( ()=>  clientService.getAll()
                      .then((resutlt) => { this.clients = resutlt.data }))
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);     
          });
        },
        refreshlist() {   
          clientService.getAll()
          .then((resutlt) => { 
            this.clients = resutlt.data.filter(x=>x.prenoms)
})
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);     
          });},
          exporter() {  clientService.exporter();
        },
          addone() {
            router.push({ name: 'clientCreate'})
         
        },
        deleteItem(item) {
          this.toDelete = item.raw
          this.dialogDelete = true
        
        },
      
        editItem(item) {
          const id = item.raw.id;
        //  this.$refs.detailClient.open(id);    
               
          router.push({ name: 'client', params: { id: id }})
        },
      },
    }
  </script>