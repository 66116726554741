<template>
    <v-row justify="center" class="text-caption">
      <v-dialog
        class="rounded-t-xl"
        v-model="dialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar  class="rounded-t-xl" color="#05445E">
            <template v-slot:image>
              <v-img
                          src="./fond_vague_long.png"
                          class="align-end"
                          gradient="to right, #05445EDD, #05445EFF"
                          cover
                        ></v-img></template>
            <v-btn icon color="white" @click="dialog = false">
              <v-icon>mdi:mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="color:white">Dictionnaire Commun</v-toolbar-title>
         
          </v-toolbar>
          <v-form v-model="valid">
            <v-container>

                    <v-row>
                      <v-col cols="4" md="4" class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.outil"
                          label="Outil"
                          readonly
                          hide-details
                        ></v-text-field>
                      </v-col>
  
                      <v-col cols="4" md="4" class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.itemLabel"
                          label="Élément"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4"  class="pa-1">
                        <v-text-field
                          v-model="dictionnaire.nombre"
                          label="Nombre"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                
                    </v-row>
  
  
                    <v-row>
                      <v-col cols="12" class="pa-1">
                        <QuillEditor    theme="snow"  contentType="html"
                        :options="editorOption"      label="commentaire"  v-model:content="dictionnaire.content"/>
                      </v-col>
                    </v-row>
  
                         

            </v-container>
          </v-form>
          <br/>
          <br/>
          <br/>
          <v-card-actions>
  <v-btn prepend-icon="mdi:mdi-content-save"    color="#05445E"  variant="flat"  class="text-white " 
 @click="save" v-if="adherent.role=='Administrateur'" 
 >
 Enregistrer
 </v-btn>
 </v-card-actions>
        </v-card>
      </v-dialog>
   
 
    
    </v-row>
  </template>
  
  <script>
  import dictionnaireCommunService from "../services/dictionnaireCommunService.js";
  import { store } from '../store.js'
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  export default {
    components: {
    QuillEditor
  },
    data() {
      return {
        adherent:null,
        dialog: false,
        menu2: false,
        valid: false,
        dictionnaire: { outil: "", id: "", item: "", itemLabel: "", content: "", },
        editorOption: {
      modules: { 
    
         toolbar: {  container:[
                          ['bold', 'italic', 'underline'],
                          ['blockquote', 'code-block'],
                          [{ 'header': 1 }, { 'header': 2 },{ 'header': 3 }],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                          [{ 'indent': '-1' }, { 'indent': '+1' }],
                          [{ 'direction': 'rtl' }],
                          [{ 'size': ['small', false, 'large', 'huge'] }],
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                          [{ 'color': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, { 'background': [
                                       "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                        "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                        "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                        "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                        "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }],
                          [{ 'align': [] }],
                          ['clean'],
                          ['link', 'image', 'video'],
                    
                      
                        
                          
                        ],},
          imageCompressor: {
            quality: 0.9,
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: 'image/jpeg'
          }
        }},
      
      };
    },
    expose: ["open"],
    emits: ["updatedDictionnaires"],
    methods: {


      save() {
        if (this.valid) {
            dictionnaireCommunService
            .save(this.dictionnaire)
            .then(() => {
              this.dialog = false;
              this.$emit("updatedDictionnaires");
            })
            .catch((e) => {
              console.log("serveur KO");
              console.log(e);
            });
        }
      },
  



      open(comId) {
   
        this.adherent = store.user
        dictionnaireCommunService
          .get(comId)
          .then((resutlt) => {
            this.dictionnaire = resutlt.data;
            this.dialog = true;
       
          })
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);
          });
    
      },
    },
  };
  </script>
  
  <style>
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
  }
  </style>
  